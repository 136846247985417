import React from "react"

import SEO from "../components/seo"
import { NewsTeaser } from "../components/newsTeaser"

const NewsOverview = ({ data }) => {
  const { news } = data

  return (
    <>
      <SEO title="Neuigkeiten" />
      <h1>Neuigkeiten</h1>
      <NewsTeaser
        news={news && news.edges && news.edges && news.edges.map(_ => _.node)}
      />
    </>
  )
}

export default NewsOverview

export const query = graphql`
  query {
    news: allSanityNews(sort: { fields: publishedAt, order: DESC }, limit: 20) {
      edges {
        node {
          _id
          title
          abstract
          slug {
            current
          }
          publishedAt(formatString: "DD.MM.YYYY")

          mainImage {
            hotspot {
              x
              y
            }
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`
